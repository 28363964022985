import Event from './event';
import EventArgs from './event-args';

export default class EventHandler<T> extends Event<T> {
    Send(sender: any, e: T) {
        this._handlers.forEach(async h => {
            try {
                h(sender, e);
            }
            catch (e){ 
                console.error(e);
            }
        });
    }
} 