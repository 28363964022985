import 'reflect-metadata';

import BaseComponent from "./base-component";

import RdshFooter from './page/footer/component';
import RdshMenu from './page/menu/component';
import RdshHeader from './page/header/component';
import RdshMedia from './page/media/component';

import { vuetifyComponents } from "./vuetify-components";

export default BaseComponent;

const rdshComponents = {
    'r-header': RdshHeader,
    'r-footer': RdshFooter,
    'r-menu': RdshMenu,
    'r-media': RdshMedia
}

// а нужно ваще?
const extend = function <First, Second>(first: First, second: Second): First & Second {
    const result: Partial<First & Second> = {};
    for (const prop in first) {
        if (Object.prototype.hasOwnProperty.call(first, prop)) {
            (result as First)[prop] = first[prop];
        }
    }
    for (const prop in second) {
        if (Object.prototype.hasOwnProperty.call(second, prop)) {
            (result as Second)[prop] = second[prop];
        }
    }
    return result as First & Second;
}

export { extend, rdshComponents, vuetifyComponents };