import 'reflect-metadata';
import { injectable } from 'inversify';

import { ActionResult, getCookie, setCookie } from '@/api';

import IAuthorizationService from './i-authorization-service';
import { RestClient } from 'typed-rest-client';

@injectable()
export default class AuthorizationService implements IAuthorizationService {

    AccessToken: string | null | undefined = '';
    constructor() {
        let accessCookie = getCookie('AccessToken');
        if (accessCookie !== null) {
            this.AccessToken = accessCookie;
        }
    }

    async Hello(): Promise<ActionResult<any>> {
        let client: RestClient = new RestClient('wp-app', window.location.origin, [], {
            headers: {
                "Authorization": this.AccessToken
            }
        });

        let response = await client.get<AjaxResult<any>>(`/api/v1/auth/hello`);
        
        if (response.result?.data) {
            if ('access_token' in response.result?.data) {
                this.AccessToken = response.result?.data.access_token;
                if (this.AccessToken !== undefined && this.AccessToken !== null) {
                    setCookie('AccessToken', this.AccessToken);
                }
            }
        }

        return ActionResult.SuccessData(response.result?.Data);
    }
    async AuthByPhone(phone: number): Promise<ActionResult<any>> {
        let client: RestClient = new RestClient('wp-app', window.location.origin, [], {
            headers: {
                "Authorization": this.AccessToken
            }
        });

        let response = await client.create<AjaxResult<string>>(`/api/v1/auth/phone`, { phone_number: phone });

        return ActionResult.SuccessData(response.result?.Data);
    }
    async VerifyByPhone(phone: number, code: number): Promise<ActionResult<any>> {
        let client: RestClient = new RestClient('wp-app', window.location.origin, [], {
            headers: {
                "Authorization": this.AccessToken
            }
        });

        let response = await client.create<AjaxResult<string>>(`/api/v1/auth/phone/verify`, { phone_number: phone, code: code });

        return ActionResult.SuccessData(response.result?.Data);
    }
    async VerifyByPassword(login: string, password: string): Promise<ActionResult<any>> {
        let client: RestClient = new RestClient('wp-app', window.location.origin, [], {
            headers: {
                "Authorization": this.AccessToken
            }
        });

        let response = await client.create<AjaxResult<string>>(`/api/v1/auth/password`, { login: login, password: password });

        return ActionResult.SuccessData(response.result?.Data);
    }
}
class AjaxResult<T> {
    Result = 0;
    Message = '';
    Data?: T;
    data?: T;
}