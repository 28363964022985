export default class Media { 
    Id: any;
    Subdirection?: any;
    Scale?: any;
    Type?: any;
    Contacts?: any;
    Dates?: any;
    Documents?: any;
    Image?: any;
    Description? = '';
    Name = '';
    ShortName? = '';
    _className = this.constructor.name;

}