import MainPage from '@/pages/main/page'

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        name: 'main',
        path: '/',
        component: MainPage,
        meta: {
            name: 'Главная'
        }
    }

]

const router = new VueRouter({
    routes
})

export default router
