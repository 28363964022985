
import Component from 'vue-class-component';
import BaseComponent from '@/components';
import { INotificationService, NotificationEventArgs, NotificationSymbols, NotificationTypes } from '@/api';
import moment, { Moment } from 'moment';

@Component
export default class BasePage extends BaseComponent {

    private _pageLoading = false;
    protected _pageNotifitcationService!: INotificationService;

    set PageLoading(val: boolean) {
        this._pageLoading = val;
        this._pageNotifitcationService.SendSystemNotification("PageLoading", val);
    }

    get PageLoading(): boolean {
        return this._pageLoading;
    }

    constructor() {
        super();
        this._pageLoading = false;
    }

    created() {
        this._pageNotifitcationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);

        this._pageNotifitcationService.NotificationSended.Subscribe(this.notification);
    }

    beforeDestroy() {
        this._pageNotifitcationService.NotificationSended.Unsubscribe(this.notification);
    }
    notification(sender: any, e: NotificationEventArgs): void {
        console.log(sender, e);
    }
    
}
