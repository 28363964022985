import Vue from 'vue';
import { Inject } from 'vue-property-decorator';
import ComponentVue from 'vue-class-component';
import GlobalService, { GlobalSymbols } from '@/api';

@ComponentVue
export default class BaseComponent extends Vue {
    @Inject(GlobalSymbols.GlobalService)
    GlobalService!: GlobalService;

    StringWithZero(value: number): string {
        if (value > 9 && value <= 100) {
            return value.toString();
        }
        else {
            return '0' + value.toString();
        }
    }
}
