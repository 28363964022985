import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import { extend, rdshComponents, vuetifyComponents } from '../components';
import ru from 'vuetify/src/locale/ru';
import Vuetify from 'vuetify';

const allComponents = extend(rdshComponents, vuetifyComponents);


export default new Vuetify({
    lang: {
        locales: { ru },
        current: 'ru',
    },
    theme: {
    }
});
Vue.use(Vuetify, { components: allComponents });