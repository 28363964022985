import GlobalService, { GlobalSymbols } from '@/api';
import Vue from 'vue'
import App from './App.vue'
import router from './router';
import vuetify from './vuetify';
import * as moment from 'moment';
import 'moment/locale/ru';



moment.locale('ru');
Vue.prototype.moment = moment

const globalService = new GlobalService();
globalService.init();

new Vue({
    router,
    render: h => h(App),
    vuetify,
    provide: {
        [GlobalSymbols.GlobalService]: globalService
    }
}).$mount('#app')

