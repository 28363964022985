import 'reflect-metadata';
import { Container } from 'inversify';

import { ServiceRegisteredRecord } from './service-registered-record';

import * as Notification from '../notification';
import * as Media from '../media';
import * as Authorization from '../auth';
import * as Contents from '../contents';

export const GlobalSymbols = {
    GlobalService: Symbol.for("GlobalService")
}

export default class GlobalService {

    _settings!: any;
    protected _container: Container = new Container();
    protected _records: Array<ServiceRegisteredRecord> = new Array<ServiceRegisteredRecord>();

    protected static _settings: any;

    static SetSettings<T extends any>(settings: T): void{
        this._settings = settings;
    }

    static GetSettings<T extends any>(): T {
        return this._settings as T;
    }

    init(): void {

        this.registerAllSymbols();
        this.registerServices();
    }

    Get<T>(type: symbol): T {
        return this._container.get<T>(type);
    }

    protected registerServices(redefinedServices?: Array<ServiceRegisteredRecord>) {
        if (redefinedServices == null) {
            this._records.forEach(item => {
                const bind = this._container.bind(item.Key).to(item.Value);

                if (item.IsSingelton) bind.inSingletonScope();
            });

            return;
        }

        this._records.forEach(item => {
            let service = redefinedServices.find(x => x.Key == item.Key);

            if (service == null) service = item;

            const bind = this._container.bind(service.Key).to(service.Value);

            if (service.IsSingelton) bind.inSingletonScope();
        });

        this._container.bind<GlobalService>(GlobalSymbols.GlobalService).toConstantValue(this);
    }

    protected registerAllSymbols(): void {
        this._records = new Array<ServiceRegisteredRecord>(
            new ServiceRegisteredRecord(Media.MediaServiceSymbols.MediaFakeProvider, Media.MediaFakeProvider),
            new ServiceRegisteredRecord(Contents.ContentsServiceSymbols.ContentsService, Contents.ContentsService),
            new ServiceRegisteredRecord(Notification.NotificationSymbols.NotificationService, Notification.DefaultNotificationService),
            new ServiceRegisteredRecord(Authorization.AuthorizationServiceSymbols.AuthorizationService, Authorization.AuthorizationService)
        );
    }

    protected registerAdditionalService(additionalServices: Array<ServiceRegisteredRecord>) {
        additionalServices.forEach(item => {
            const bind = this._container.bind(item.Key).to(item.Value);

            if (item.IsSingelton) bind.inSingletonScope();
        });
    }

}