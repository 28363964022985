import { injectable, inject } from 'inversify';

import { Media } from '@/entities';
import { ActionResult } from '@/api';
import { MediaServiceSymbols } from './symbols';

import IMediaProvider from './i-media-provider';
import IMediaService from './i-media-service';


@injectable()
export default class MediaService implements IMediaService {
    private readonly _provider: IMediaProvider;

    constructor(@inject(MediaServiceSymbols.MediaProvider) provider: IMediaProvider) {
        this._provider = provider;
    }

    async GetMedia(id: number): Promise<ActionResult<Media>> {
        return await this._provider.GetMedia(id);
    }
    async GetMediaList(params: any): Promise<ActionResult<Array<Media>>> {
        return await this._provider.GetMediaList(params);
    }
}