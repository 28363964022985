import './style.scss';

import Component, { mixins } from 'vue-class-component';
import Vue from 'vue';
import BaseComponent from '@/components/base-component';
import { EventArgs, EventHandler, INotificationService, NotificationSymbols } from '@/api';

@Component({
    template: require("./template.html")
})
export default class RdshHeader extends mixins(BaseComponent, Vue) {
   
    protected notificationService!: INotificationService;

    created() {
        this.notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
    }

    SetShowMenu() {
        this.notificationService.Send({Type: 'PageEvent', Event: 'OpenMenu'});
    }
    SetShowAuth() {
        this.notificationService.Send({Type: 'PageEvent', Event: 'OpenAuth'});
    }
    SetShowRegistration() {
        this.notificationService.Send({Type: 'PageEvent', Event: 'OpenRegister'});
    }
}