import 'reflect-metadata';
import { injectable } from 'inversify';

import { RestClient } from 'typed-rest-client';
import { ActionResult } from '../common';
import IContentsService from './i-contents-service';

@injectable()
export default class ContentsService implements IContentsService {
    async GetTopString() {
        let client: RestClient = new RestClient('wp-app', window.location.origin, [], {
            headers: {
                "Authorization": 'Bearer 98c9b24c5290f868fa8bd78b999d8bf50a4de75804bff973473a769192a1fdde08adc9fb0b864af8fa439e6e2338219ad2ace10e7e2c6ec0f0bbd8bff55bc83dd4ac48588c439ba1570318afcd300ff15c61b6e9b812bb4ca803409778ca957485d5b2bd004c82c9cb6f6881955272b29a51bfb06ff4d01d459a33bfcc6ffe0c'
            }
        });

        let response = await client.get<AjaxResult<string>>(`http://localhost:1337/api/content?populate[MotivationBlock][populate]=*`);

        return ActionResult.SuccessData(response.result?.Data);
    }
    async Participate() {
        let client: RestClient = new RestClient('wp-app', window.location.origin, [], {
            headers: {
                "Authorization": 'Bearer 98c9b24c5290f868fa8bd78b999d8bf50a4de75804bff973473a769192a1fdde08adc9fb0b864af8fa439e6e2338219ad2ace10e7e2c6ec0f0bbd8bff55bc83dd4ac48588c439ba1570318afcd300ff15c61b6e9b812bb4ca803409778ca957485d5b2bd004c82c9cb6f6881955272b29a51bfb06ff4d01d459a33bfcc6ffe0c'
            }
        });

        let response = await client.create<AjaxResult<string>>(`http://localhost:1337/api/participants`, { 
            data: {
                FirstName: 'Литвак',
                SecondName: 'Иван',
                Patronymic: 'Михайлович',
                DateOfBirth: '2022-08-23',
                Phone: '123123123'
            }
         });

        return ActionResult.SuccessData(response.result?.Data);
    }


}
class AjaxResult<T> {
    Result = 0;
    Message = '';
    Data?: T;
    data?: T;
}