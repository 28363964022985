import './style.scss';

import Component, { mixins } from 'vue-class-component';
import Vue from 'vue';
import BaseComponent from '@/components/base-component';

@Component({
    template: require("./template.html")
})
export default class RdshMedia extends mixins(BaseComponent, Vue) {
}