import { injectable } from 'inversify';

import { ActionResult } from '../common';
import IMediaProvider from './i-media-provider';
import { Media } from '@/entities';

@injectable()
export default class MediaFakeProvider implements IMediaProvider {

    async GetMedia(id: number): Promise<ActionResult<Media>> {
        
        const data = new Media();
        data.Name = 'Fake Media Name';
        data.Dates = "22.02.22"
        data.Description = "Описание медиа"
        

        return ActionResult.SuccessData<Media>(data);
        
    }
    async GetMediaList(params: any): Promise<ActionResult<Array<Media>>> {
        
        const data = [new Media(), new Media(), new Media(), new Media(), new Media(), new Media(), new Media(),new Media()];
        data[0].Name = 'Fake Media Name 1';
        data[0].Id = 0;
        data[0].Dates = "22.02.22"
        data[0].Description = "fake media description 1"

        data[1].Name = 'Fake Media Name 2';
        data[1].Id = 1;
        data[1].Dates = "22.02.22"
        data[1].Description = "fake media description 2"

        data[2].Name = 'Fake Media Name 3';
        data[2].Id = 2;
        data[2].Dates = "22.02.22"
        data[2].Description = "fake media description 3"

        data[3].Name = 'Fake Media Name 4';
        data[3].Id = 3;
        data[3].Dates = "22.02.22"
        data[3].Description = "fake media description 4"

        data[4].Name = 'Fake Media Name 5';
        data[4].Id = 4;
        data[4].Dates = "22.02.22"
        data[4].Description = "fake media description 5"

        data[5].Name = 'Fake Media Name 6';
        data[5].Id = 5;
        data[5].Dates = "22.02.22"
        data[5].Description = "fake media description 6"

        data[6].Name = 'Fake Media Name 7';
        data[6].Id = 6;
        data[6].Dates = "22.02.22"
        data[6].Description = "fake media description 7"

        data[7].Name = 'Fake Media Name 8';
        data[7].Id = 7;
        data[7].Dates = "22.02.22"
        data[7].Description = "fake media description 8"


        data.forEach(function(el: Media) {
            console.log(el, el.constructor.name);
        });

        return ActionResult.SuccessData<Array<Media>>(data);
    }
}