import './style.scss';

import Component, { mixins } from 'vue-class-component';
import Vue from 'vue';
import BaseComponent from '@/components/base-component';

@Component({
    template: require("./template.html")
})
export default class RdshFooter extends mixins(BaseComponent, Vue) {
    links = [
        'Home',
        'About Us',
        'Team',
        'Services',
        'Blog',
        'Contact Us',
    ];
}