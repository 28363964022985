import './style.scss';

import Component, { mixins } from 'vue-class-component';
import Vue from 'vue';
import BasePage from '@/pages/base-page';
import moment from 'moment';
import { ContentsServiceSymbols, IContentsService } from '@/api';

@Component({
    template: require("./template.html")
})
export default class MainPage extends mixins(BasePage, Vue) {

    
    _contentsService!: IContentsService;
    bla: any;
    async mounted() {
        this._contentsService = this.GlobalService.Get<IContentsService>(ContentsServiceSymbols.ContentsService);

        const contents = await this._contentsService.GetTopString();
        console.log(contents);
        if (contents.Data) {
            this.bla = contents;
        }
    }

    async Participate(): Promise<void> {
        const contents = await this._contentsService.Participate();
        console.log(contents);
    }

    Logo = require('@/assets/logo.svg');
    NewsImage = require('@/assets/news-image-1.png');
    MediaCarousel = 0;
    EventsCarousel = 0;
    ContestOverlay = false;
    Partner1 = require('@/assets/partner-1.png');
    Partner2 = require('@/assets/partner-2.png');
    Partner3 = require('@/assets/partner-3.png');
    PartnerSlideGroup = 0;
    ShowArrows = false;
    ShowArrowsOnHover = true;
    HideDelimeters = true;

    IsFormValid = false;

    FirstName = '';
    LastName = '';
    SecondName = '';

    Date: any = null;
    DateOfBirthPopup = false;
    get ComputedDateFormatted(): string {
        return this.Date !== null ? moment(this.Date).format('DD.MM.YYYY') : '';
    }
    AtLeast7YearsMonth = moment().subtract(7, 'years').format('YYYY-MM');
    AtLeast7Years = moment().subtract(7, 'years').format('YYYY-MM-DD');

    Email = '';
    Phone = '';

    get NameRules() {
        return [
            (v: string) => !!v || 'Введите текст',
            (v: string) => /(?<=(^|[^а-я]))(([уyu]|[нзnz3][аa]|(хитро|не)?[вvwb][зz3]?[ыьъi]|[сsc][ьъ']|(и|[рpr][аa4])[зсzs]ъ?|([оo0][тбtb6]|[пp][оo0][дd9])[ьъ']?|(.\B)+?[оаеиeo])?-?([еёe][бb6](?!о[рй])|и[пб][ае][тц]).*?|([нn][иеаaie]|([дпdp]|[вv][еe3][рpr][тt])[оo0]|[рpr][аa][зсzc3]|[з3z]?[аa]|с(ме)?|[оo0]([тt]|дно)?|апч)?-?[хxh][уuy]([яйиеёюuie]|ли(?!ган)).*?|([вvw][зы3z]|(три|два|четыре)жды|(н|[сc][уuy][кk])[аa])?-?[бb6][лl]([яy](?!(х|ш[кн]|мб)[ауеыио]).*?|[еэe][дтdt][ь']?)|([рp][аa][сзc3z]|[знzn][аa]|[соsc]|[вv][ыi]?|[пp]([еe][рpr][еe]|[рrp][оиioеe]|[оo0][дd])|и[зс]ъ?|[аоao][тt])?[пpn][иеёieu][зz3][дd9].*?|([зz3][аa])?[пp][иеieu][дd][аоеaoe]?[рrp](ну.*?|[оаoa][мm]|([аa][сcs])?([иiu]([лl][иiu])?[нщктлtlsn]ь?)?|([оo](ч[еиei])?|[аa][сcs])?[кk]([оo]й)?|[юu][гg])[ауеыauyei]?|[мm][аa][нnh][дd]([ауеыayueiи]([лl]([иi][сзc3щ])?[ауеыauyei])?|[оo][йi]|[аоao][вvwb][оo](ш|sh)[ь']?([e]?[кk][ауеayue])?|юк(ов|[ауи])?)|[мm][уuy][дd6]([яyаиоaiuo0].*?|[еe]?[нhn]([ьюия'uiya]|ей))|мля([тд]ь)?|лять|([нз]а|по)х|м[ао]л[ао]фь([яию]|[её]й))(?=($|[^а-я]))/.test(v) || 'Эй, не матерись!',
        ]
    }
    
    get EmailRules() {
        return [
            (v: string) => !!v || 'Введите e-mail',
            (v: string) => /.+@.+\..+/.test(v) || 'Введите e-mail',
        ]
    }   

    
    get PhoneRules() {
        return [
            (v: string) => (/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/.test(v)) || 'Заполните номер до конца'
        ];
    }

    AllRegions = [
        { Id: 1, Name: "Республика Адыгея" },
        { Id: 2, Name: "Республика Алтай" },
        { Id: 3, Name: "Алтайский край" },
        { Id: 4, Name: "Амурская область" },
        { Id: 5, Name: "Архангельская область" },
        { Id: 6, Name: "Астраханская область" },
        { Id: 7, Name: "Республика Башкортостан" },
        { Id: 8, Name: "Республика Бурятия" },
        { Id: 9, Name: "Белгородская область" },
        { Id: 10, Name: "Брянская область" },
        { Id: 11, Name: "Владимирская область" },
        { Id: 12, Name: "Волгоградская область" },
        { Id: 13, Name: "Вологодская область" },
        { Id: 14, Name: "Воронежская область" },
        { Id: 15, Name: "Республика Дагестан" },
        { Id: 16, Name: "Еврейская автономная область" },
        { Id: 17, Name: "Забайкальский край" },
        { Id: 18, Name: "Республика Ингушетия" },
        { Id: 19, Name: "Ивановская область" },
        { Id: 20, Name: "Иркутская область" },
        { Id: 21, Name: "Кабардино - Балкарская Республика" },
        { Id: 22, Name: "Республика Калмыкия" },
        { Id: 23, Name: "Карачаево - Черкесская Республика" },
        { Id: 24, Name: "Республика Карелия" },
        { Id: 25, Name: "Республика Коми" },
        { Id: 26, Name: "Краснодарский край" },
        { Id: 27, Name: "Красноярский край" },
        { Id: 28, Name: "Калининградская область" },
        { Id: 29, Name: "Калужская область" },
        { Id: 30, Name: "Камчатский край" },
        { Id: 31, Name: "Кемеровская область - Кузбасс" },
        { Id: 32, Name: "Кировская область" },
        { Id: 33, Name: "Костромская область" },
        { Id: 34, Name: "Курганская область" },
        { Id: 35, Name: "Курская область" },
        { Id: 36, Name: "Республика Крым" },
        { Id: 37, Name: "Ленинградская область" },
        { Id: 38, Name: "Липецкая область" },
        { Id: 39, Name: "Республика Марий Эл" },
        { Id: 40, Name: "Республика Мордовия" },
        { Id: 41, Name: "Магаданская область" },
        { Id: 42, Name: "Московская область" },
        { Id: 43, Name: "Мурманская область" },
        { Id: 44, Name: "город Москва" },
        { Id: 45, Name: "Нижегородская область" },
        { Id: 46, Name: "Новгородская область" },
        { Id: 47, Name: "Новосибирская область" },
        { Id: 48, Name: "Ненецкий автономный округ" },
        { Id: 49, Name: "Омская область" },
        { Id: 50, Name: "Оренбургская область" },
        { Id: 51, Name: "Орловская область" },
        { Id: 52, Name: "Приморский край" },
        { Id: 53, Name: "Пензенская область" },
        { Id: 54, Name: "Пермский край" },
        { Id: 55, Name: "Псковская область" },
        { Id: 56, Name: "Ростовская область" },
        { Id: 57, Name: "Рязанская область" },
        { Id: 58, Name: "Республика Саха(Якутия)" },
        { Id: 59, Name: "Республика Северная Осетия - Алания" },
        { Id: 60, Name: "Ставропольский край" },
        { Id: 61, Name: "Самарская область" },
        { Id: 62, Name: "Саратовская область" },
        { Id: 63, Name: "Сахалинская область" },
        { Id: 64, Name: "Свердловская область" },
        { Id: 65, Name: "Смоленская область" },
        { Id: 66, Name: "город Санкт - Петербург" },
        { Id: 67, Name: "город Севастополь" },
        { Id: 68, Name: "Республика Татарстан(Татарстан)" },
        { Id: 69, Name: "Республика Тыва" },
        { Id: 70, Name: "Тамбовская область" },
        { Id: 71, Name: "Тверская область" },
        { Id: 72, Name: "Томская область" },
        { Id: 73, Name: "Тульская область" },
        { Id: 74, Name: "Тюменская область" },
        { Id: 75, Name: "Удмуртская Республика" },
        { Id: 76, Name: "Ульяновская область" },
        { Id: 77, Name: "Республика Хакасия" },
        { Id: 78, Name: "Хабаровский край" },
        { Id: 79, Name: "Ханты - Мансийский автономный округ - Югра" },
        { Id: 80, Name: "Чеченская Республика" },
        { Id: 81, Name: "Чувашская Республика - Чувашия" },
        { Id: 82, Name: "Челябинская область" },
        { Id: 83, Name: "Чукотский автономный округ" },
        { Id: 84, Name: "Ярославская область" },
        { Id: 85, Name: "Ямало - Ненецкий автономный округ" },
    ]
}