import './style.scss';

import Component, { mixins } from 'vue-class-component';
import Vue from 'vue';
import BaseComponent from '@/components/base-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { INotificationService, NotificationEventArgs, NotificationSymbols, NotificationTypes } from '@/api';

@Component({
    template: require("./template.html")
})
export default class RdshMenu extends mixins(BaseComponent, Vue) {
    ShowMenu = false;

    protected notificationService!: INotificationService;
    
    created() {
        this.notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
        this.notificationService.NotificationSended.Subscribe(this.notification);

        if (this.$router.options.routes) {
            this.$router.options.routes.forEach(route => {
                this.Items.push({
                    name: route.meta?.name
                    , path: route.path
                })
            })
        }
    }
    notification(sender: any, e: any) {
        if (e.Type == 'PageEvent' && e.Event == 'OpenMenu') {
            this.ShowMenu = !this.ShowMenu;
        }
    }

    Items: any = [];

}